import algoliasearch from 'algoliasearch'
import { AxiosResponse } from 'axios'
import type { BaseHit, Hit } from 'instantsearch.js/es/types'

import * as config from '../../config'
import { BundlePrice, ProductPrice } from '../../contexts/Cart/types'
import { isBolivia, isChile } from '../../i18n'
import { Product } from '../../types'
import { handleServiceError } from '../../utils/errors'
import { toZeroDecimals } from '../../utils/format'
import { toCurrency } from '../../utils/strings'
import { BundleInputPrice, getProductPrices } from '../cart'
import fetch from '../clients/algolia'
import fetchQuestions from '../clients/supportQuestionsAlgolia'
import {
  AlgoliaAttributes,
  AlgoliaFacetResponse,
  AlgoliaProduct,
  AlgoliaResponse,
  AlgoliaSupportQuestion,
} from './types'

type ProductResponse = AlgoliaResponse<AlgoliaProduct>
type SupportQuestionResponse = AlgoliaResponse<AlgoliaSupportQuestion>

export const searchClient = algoliasearch(config.ALGOLIA_APP_ID, config.ALGOLIA_API_KEY)

export type formatProductProps =
  | {
      isBundle: false
      product: AlgoliaProduct | Hit<BaseHit>
      price?: ProductPrice
      isDutyFree: boolean
      priceList: number
      dutyFreePriceList?: number
      customerBranchOfficeId: string
      customerChannel?: string
    }
  | {
      isBundle: true
      product: AlgoliaProduct | Hit<BaseHit>
      price?: BundlePrice
      isDutyFree: false
      priceList: number
      dutyFreePriceList?: number
      customerBranchOfficeId: string
      customerChannel?: string
    }

export const formatProduct = ({
  isBundle,
  product,
  price,
  isDutyFree,
  priceList,
  dutyFreePriceList,
  customerBranchOfficeId,
  customerChannel,
}: formatProductProps): Product => {
  let isAvailable = true

  if (product.notAvailableFor && product.notAvailableFor.length > 0) {
    const isNotAvailable = product.notAvailableFor.some(
      (unavailability) =>
        unavailability.branchOffice === customerBranchOfficeId && unavailability.channel === customerChannel,
    )

    isAvailable = !isNotAvailable
  }

  if (product.notAvailableBranches && product.notAvailableBranches.length > 0) {
    const isNotAvailable = product.notAvailableBranches.includes(customerBranchOfficeId)

    isAvailable = !isNotAvailable
  }

  return {
    isBundle,
    bundleProducts: product.bundleProducts,
    size: product.size,
    hiddenBranches: product.hiddenBranches,
    categoryPriority: product.categoryPriority,
    brand: product.pimBrand,
    basisBrand: product.brand,
    category: product.mainCategoryName,
    subCategory: product.categoryName,
    flavor: product.pimFlavor,
    format: product.format,
    groups: product.groups,
    image: product.contentfulImage || '',
    itemsPerBox: isBolivia && product.pimPackaging === 'BAG IN BOX' ? 1 : product.itemsPerBox, // BOLIVIA BIB PATCH
    sugar: product.sugar,
    name: product.pimName,
    netContent: product.pimNetContent,
    packaging: product.pimPackaging,
    container: product.container,
    price: (isBundle ? price?.bundlePrice : price?.boxPrice) || 0,
    basePrice:
      (isBundle
        ? price?.grossValue
        : price?.price ??
          product.prices.find(({ list }) => `${list}` === `${dutyFreePriceList}` || `${list}` === `${priceList}`)
            ?.price) ?? 0,
    rawPrice: (isBundle ? price?.notVisibleDiscountBundlePrice : price?.notVisibleDiscountsTotalValue) || 0,
    prices: product.prices,
    sku: product.sku,
    pimEan: product.pimEan,
    unit: product.pimUnit,
    displayPrice: toCurrency((isBundle ? price?.bundlePrice : price?.boxPrice) || 0),
    displayRawPrice: toCurrency(
      (isBundle ? price?.notVisibleDiscountBundlePrice : price?.notVisibleDiscountsTotalValue) || 0,
    ),
    objectID: product.objectID,
    isReturnable: product.returnability === 'RETORNABLE',
    isDutyFree,
    discount: toZeroDecimals(
      (isBundle ? price?.actualReductionPercentage : price?.actualVisibleDiscountReductionPercentage) || 0,
    ),
    priceDetails: price,
    isPlanApplied: isBundle ? false : Boolean(price?.plan),
    ascOrder: product.ascOrder,
    descOrder: product.descOrder,
    isAvailable,
  }
}

export function getProduct(
  sku: string,
): (
  branchOfficeId: string,
  isCurrentStoreDutyFree: boolean,
  storeId: string,
  priceList: number,
  dutyFreePriceList?: number,
  customerChannel?: string,
) => Promise<Product> {
  return async (branchOfficeId, isCurrentStoreDutyFree, storeId, priceList, dutyFreePriceList, customerChannel) => {
    const { data }: AxiosResponse<ProductResponse> = await fetch('/query', {
      data: { filters: `sku=${sku}`, clickAnalytics: true },
      method: 'POST',
    })

    let productPrice: ProductPrice
    let bundlePrice: BundlePrice | undefined

    if (data.hits[0].isBundle) {
      bundlePrice = (
        await getProductPrices({
          bundles:
            [
              {
                bundleId: +sku,
                bundleAmount: 1,
                products: data.hits[0].bundleProducts.map(({ sku, boxAmount, bottleAmount }) => ({
                  productId: sku,
                  boxAmount,
                  bottleAmount,
                })),
              },
            ] ?? [],
          storeId,
          branchOfficeId,
        })
      ).bundles?.[0]
    } else {
      productPrice = (
        await getProductPrices({
          products: [
            {
              productId: +sku,
              isDutyFree:
                (data.hits[0].isDutyFree &&
                  isCurrentStoreDutyFree &&
                  Boolean(dutyFreePriceList) &&
                  data.hits[0].cifValues?.some((value) => `${value.branchOfficeId}` === `${branchOfficeId}`) &&
                  data.hits[0].prices.some((price) => price.list === dutyFreePriceList)) ||
                false,
            },
          ],
          storeId,
          branchOfficeId,
        })
      ).products[0]
    }

    const results = (
      data.hits.map((hit) => {
        const product: formatProductProps = hit.isBundle
          ? {
              isBundle: true,
              product: hit,
              price: bundlePrice,
              isDutyFree: false,
              priceList,
              customerBranchOfficeId: branchOfficeId,
              customerChannel,
            }
          : {
              isBundle: false,
              product: hit,
              price: productPrice,
              isDutyFree:
                (hit.isDutyFree &&
                  isCurrentStoreDutyFree &&
                  Boolean(dutyFreePriceList) &&
                  hit.cifValues?.some((value) => `${value.branchOfficeId}` === `${branchOfficeId}`) &&
                  hit.prices.some((price) => price.list === dutyFreePriceList)) ||
                false,
              priceList,
              dutyFreePriceList,
              customerBranchOfficeId: branchOfficeId,
              customerChannel,
            }

        return { ...formatProduct(product), __queryID: data.queryID }
      }) || []
    ).filter((product) => {
      if ((isChile && (product.basePrice === 100000 || product.basePrice === 10000)) || product.basePrice <= 0)
        return false

      return true
    })

    return results[0]
  }
}

export const getProducts = (
  filters: string,
  attributes?: AlgoliaAttributes,
  optionalFilters?: string[],
  withoutPrices?: boolean,
): ((
  branchOfficeId: string,
  isCurrentStoreDutyFree: boolean,
  storeId: string,
  priceList: number,
  dutyFreePriceList?: number,
  customerChannel?: string,
) => Promise<Product[]>) => {
  return async (branchOfficeId, isCurrentStoreDutyFree, storeId, priceList, dutyFreePriceList, customerChannel) => {
    try {
      const { data }: AxiosResponse<ProductResponse> = await fetch('/query', {
        data: {
          filters,
          optionalFilters: [
            ...(branchOfficeId
              ? [
                  `notAvailableBranches:-${branchOfficeId}<score=1000>`,
                  `notAvailableFor.branchOffice:-${branchOfficeId}<score=1000>`,
                ]
              : []),
            ...(customerChannel ? [`notAvailableFor.channel:-${customerChannel}<score=1000>`] : []),
            ...(optionalFilters || []),
          ],
          clickAnalytics: true,
          ...attributes,
        },
        method: 'POST',
      })

      let productPrices: ProductPrice[] = []
      let bundlePrices: BundlePrice[] = []

      if (!withoutPrices) {
        const productsToGetPrices = data.hits
          .filter((product) => !product.isBundle)
          .map(({ sku, isDutyFree, cifValues, prices }) => ({
            productId: sku,
            isDutyFree:
              (isDutyFree &&
                isCurrentStoreDutyFree &&
                Boolean(dutyFreePriceList) &&
                cifValues?.some((value) => `${value.branchOfficeId}` === `${branchOfficeId}`) &&
                prices.some((price) => price.list === dutyFreePriceList)) ||
              false,
          }))

        const bundlesToGetPrices: BundleInputPrice[] = data.hits
          .filter((product) => product.isBundle)
          .map(({ sku, bundleProducts }) => ({
            bundleId: sku,
            bundleAmount: 1,
            products: bundleProducts.map(({ sku, boxAmount, bottleAmount }) => ({
              productId: sku,
              boxAmount,
              bottleAmount,
            })),
            branchOfficeId,
          }))

        if (productsToGetPrices.length > 0 || bundlesToGetPrices.length > 0) {
          const prices = await getProductPrices({
            products: productsToGetPrices,
            bundles: bundlesToGetPrices,
            storeId,
            branchOfficeId,
          })
          productPrices = prices.products
          bundlePrices = prices.bundles ?? []
        }
      }

      const products = data.hits
        .map((hit) => {
          const product: formatProductProps = hit.isBundle
            ? {
                isBundle: true,
                product: hit,
                price: bundlePrices.find(({ sku }) => sku === hit.sku),
                isDutyFree: false,
                priceList,
                customerBranchOfficeId: branchOfficeId,
                customerChannel,
              }
            : {
                isBundle: false,
                product: hit,
                price: productPrices.find(({ productId }) => productId === hit.sku),
                isDutyFree:
                  (hit.isDutyFree &&
                    isCurrentStoreDutyFree &&
                    Boolean(dutyFreePriceList) &&
                    hit.cifValues?.some((value) => `${value.branchOfficeId}` === `${branchOfficeId}`) &&
                    hit.prices.some((price) => price.list === dutyFreePriceList)) ||
                  false,
                priceList,
                dutyFreePriceList,
                customerBranchOfficeId: branchOfficeId,
                customerChannel,
              }
          return {
            ...formatProduct(product),
            __queryID: data.queryID,
          }
        })
        .filter((product) => {
          if ((isChile && (product.basePrice === 100000 || product.basePrice === 10000)) || product.basePrice <= 0)
            return false

          return true
        })

      return products
    } catch (error) {
      console.error(error)
      return Promise.resolve(handleServiceError(error))
    }
  }
}

export const getQuestions = async (categories?: string[]) => {
  let filters = ''
  if (categories?.length) {
    filters = categories
      .map((category) => {
        return `(category:"${category}")`
      })
      .join(' AND ')
  }
  try {
    const { data }: AxiosResponse<SupportQuestionResponse> = await fetchQuestions('/query', {
      data: {
        hitsPerPage: 100,
        filters,
      },
      method: 'POST',
    })
    return data.hits
  } catch (error) {
    console.error(error)
    return Promise.resolve(handleServiceError(error))
  }
}

export const getQuestion = async (slug: string) => {
  try {
    const { data }: AxiosResponse<SupportQuestionResponse> = await fetchQuestions('/query', {
      data: {
        filters: `slug:${slug}`,
      },
      method: 'POST',
    })
    return data.hits[0]
  } catch (error) {
    console.error(error)
    return Promise.resolve(handleServiceError(error))
  }
}

export const getAvailableCategoriesFilters = async (filters: string) => {
  try {
    const { data }: AxiosResponse<AlgoliaFacetResponse> = await fetch('/facets/mainCategoryName/query', {
      data: {
        filters,
        maxFacetHits: 100,
      },
      method: 'POST',
    })
    return data.facetHits.map(({ value }) => `${value}`)
  } catch (error) {
    console.error(error)
    return Promise.resolve(handleServiceError(error))
  }
}
